import { ColumnConfig } from 'theme';
import i18n from '../../i18n';
import { FilledForm } from '@ts/models';

export const formsListColumnConfigs: Array<ColumnConfig<FilledForm>> = [
  {
    id: 'title',
    label: i18n.t('participantFormsPage.columnLabels.title'),
    flex: 4
  },
  {
    id: 'completed',
    label: i18n.t('participantFormsPage.columnLabels.completed'),
    flex: 4
  },
  {
    id: 'links',
    label: '',
    flex: 1,
    alignItems: 'flex-end'
  }
];

export const participantForms = {
  newFormBtnLabel: i18n.t('participantFormsPage.newFormBtnLabel'),
  exitFormConfirmationTitle: i18n.t(
    'participantFormsPage.exitFormConfirmationTitle'
  ),
  exitFormConfirmationText: i18n.t(
    'participantFormsPage.exitFormConfirmationText'
  ),
  exitFormGoBackBtnLabel: i18n.t('participantFormsPage.exitFormGoBackBtnLabel'),
  exitFormDiscardBtnLabel: i18n.t(
    'participantFormsPage.exitFormDiscardBtnLabel'
  ),
  requiredFieldsDisclaimer: i18n.t(
    'participantFormsPage.requiredFieldsDisclaimer'
  ),
  formValidationError: i18n.t('participantFormsPage.formValidationError'),
  requiredFieldError: i18n.t('participantFormsPage.requiredFieldError'),
  numberFieldError: i18n.t('participantFormsPage.numberFieldError')
};
