import { ColumnConfig, ParticipantsContentConfiguration } from 'theme';
import { GetParticipant } from '@ts/models';
import i18n from '../../i18n';
import { participantSurveys } from './participantSurveysContentConfiguration';
import { participantForms } from './participantFormsContentConfiguration';

const columnConfigs: ColumnConfig<GetParticipant>[] = [
  {
    id: 'name',
    label: i18n.t('participantContent.columnConfigs.name'),
    sortable: true
  },
  {
    id: 'site',
    label: i18n.t('participantContent.columnConfigs.site'),
    sortable: true
  },
  {
    id: 'status',
    label: i18n.t('participantContent.columnConfigs.status'),
    sortable: true
  },
  { id: 'flags', label: i18n.t('participantContent.columnConfigs.flags') },
  { id: 'tags', label: i18n.t('participantContent.columnConfigs.tags') }
];

const participantDetails = {
  participantList: i18n.t('participantDetailsPage.participantList'),
  participantId: i18n.t('participantDetailsPage.participantId'),
  participantStatus: i18n.t('participantDetailsPage.participantStatus'),
  participantFlags: i18n.t('participantDetailsPage.participantFlags'),
  participantTags: i18n.t('participantDetailsPage.participantTags'),
  participant: i18n.t('participantDetailsPage.participant')
};

const participantCharts = {
  chartWeekView: i18n.t('participantChartsPage.weekView'),
  charDayView: i18n.t('participantChartsPage.dayView'),
  infoBoxTitle: i18n.t('participantChartsPage.infoBoxTitle'),
  notificationsTitle: i18n.t('participantChartsPage.notificationsTitle'),
  notificationDateHeader: i18n.t(
    'participantChartsPage.notificationDateHeader'
  ),
  notificationTimeHeader: i18n.t(
    'participantChartsPage.notificationTimeHeader'
  ),
  notificationTypeHeader: i18n.t(
    'participantChartsPage.notificationTypeHeader'
  ),
  emptyDataMessage: i18n.t('participantChartsPage.emptyDataMessage')
};

const participantGraphs = {
  dateRangeTitle: i18n.t('participantGraphsPage.dateRangeTitle'),
  dateRange: {
    sevenDays: i18n.t('participantGraphsPage.dateRange.sevenDays'),
    twoWeeks: i18n.t('participantGraphsPage.dateRange.twoWeeks'),
    oneMonth: i18n.t('participantGraphsPage.dateRange.oneMonth'),
    threeMonths: i18n.t('participantGraphsPage.dateRange.threeMonths')
  },
  bgmSummary: i18n.t('participantGraphsPage.bgmSummary'),
  cgmSummary: i18n.t('participantGraphsPage.cgmSummary'),
  emptyMessage: i18n.t('participantGraphsPage.emptyMessage'),
  errorMessage: i18n.t('participantGraphsPage.errorMessage')
};

export const participantDevices = {
  deviceNumber: i18n.t('participantDevicesPage.deviceNumber'),
  pairingCode: i18n.t('participantDevicesPage.pairingCode'),
  status: i18n.t('participantDevicesPage.status'),
  lastSync: i18n.t('participantDevicesPage.lastSync'),
  sensorStart: i18n.t('participantDevicesPage.sensorStart'),
  sensorExpires: i18n.t('participantDevicesPage.sensorExpires'),
  displayDevices: i18n.t('participantDevicesPage.displayDevices'),
  statusLabels: {
    connected: i18n.t('participantDevicesPage.statusLabels.connected'),
    inactive: i18n.t('participantDevicesPage.statusLabels.inactive'),
    notConnected: i18n.t('participantDevicesPage.statusLabels.notConnected'),
    notPaired: i18n.t('participantDevicesPage.statusLabels.notPaired'),
    error: i18n.t('participantDevicesPage.statusLabels.error'),
    warmup: i18n.t('participantDevicesPage.statusLabels.warmup')
  },
  showInactive: i18n.t('participantDevicesPage.showInactive'),
  hideInactive: i18n.t('participantDevicesPage.hideInactive'),
  viewHistory: i18n.t('participantDevicesPage.viewHistory'),
  history: i18n.t('participantDevicesPage.history'),
  close: i18n.t('participantDevicesPage.close')
};

const config: ParticipantsContentConfiguration = {
  searchInputPlaceholder: i18n.t('participantContent.searchInputPlaceholder'),
  sitesFilterLabel: i18n.t('participantContent.sitesFilterLabel'),
  statusFilterLabel: i18n.t('participantContent.statusFilterLabel'),
  flagsFilterLabel: i18n.t('participantContent.flagsFilterLabel'),
  tagsFilterLabel: i18n.t('participantContent.tagsFilterLabel'),
  displayingParticipantsLabel: i18n.t(
    'participantContent.displayingParticipantsLabel'
  ),
  participantsLabel: i18n.t('participantContent.participantsLabel'),
  participantsCreateButtonLabel: i18n.t(
    'participantContent.participantsCreateButtonLabel'
  ),
  columnConfigs,
  statusLabelActive: i18n.t('participantContent.statusLabelActive'),
  statusLabelInactive: i18n.t('participantContent.statusLabelInactive'),
  statusLabelInvited: i18n.t('participantContent.statusLabelInvited'),
  statusLabelLocked: i18n.t('participantContent.statusLabelLocked'),
  searchEmptyListMessage: i18n.t('participantContent.searchEmptyListMessage'),
  filtersEmptyListMessage: i18n.t('participantContent.filtersEmptyListMessage'),
  emptyListMessage: i18n.t('participantContent.emptyListMessage'),
  errorListMessage: i18n.t('participantContent.errorListMessage'),
  createModalTitle: i18n.t('participantContent.createModalTitle'),
  createModalDisclaimer: i18n.t('participantContent.createModalDisclaimer'),
  formFields: {
    site: {
      label: i18n.t('participantContent.formFields.site.label'),
      placeholder: i18n.t('participantContent.formFields.site.placeholder')
    },
    participantId: {
      label: i18n.t('participantContent.formFields.participantId.label'),
      placeholder: i18n.t(
        'participantContent.formFields.participantId.placeholder'
      )
    },
    contactMethod: {
      label: i18n.t('participantContent.formFields.contactMethod.label'),
      placeholder: i18n.t(
        'participantContent.formFields.contactMethod.placeholder'
      )
    },
    email: {
      label: i18n.t('participantContent.formFields.email.label'),
      placeholder: i18n.t('participantContent.formFields.email.placeholder')
    },
    phoneNumber: {
      label: i18n.t('participantContent.formFields.phoneNumber.label'),
      placeholder: i18n.t(
        'participantContent.formFields.phoneNumber.placeholder'
      )
    },
    dateInformedConsent: {
      label: i18n.t('participantContent.formFields.dateInformedConsent.label'),
      placeholder: i18n.t(
        'participantContent.formFields.dateInformedConsent.placeholder'
      )
    },
    state: {
      label: i18n.t('participantContent.formFields.state.label'),
      placeholder: i18n.t('participantContent.formFields.state.placeholder')
    },
    zipCode: {
      label: i18n.t('participantContent.formFields.zipCode.label'),
      placeholder: i18n.t('participantContent.formFields.zipCode.placeholder')
    },
    birthday: {
      label: i18n.t('participantContent.formFields.birthday.label'),
      placeholder: i18n.t('participantContent.formFields.birthday.placeholder')
    }
  },
  contactMethodLabelEmail: i18n.t('participantContent.contactMethodLabelEmail'),
  contactMethodLabelPhone: i18n.t('participantContent.contactMethodLabelPhone'),
  createModalCancelButtonLabel: i18n.t(
    'participantContent.createModalCancelButtonLabel'
  ),
  createModalSubmitButtonLabel: i18n.t(
    'participantContent.createModalSubmitButtonLabel'
  ),
  errorModalDisclaimer: i18n.t('participantContent.errorModalDisclaimer'),
  ...participantDetails,
  ...participantCharts,
  participantGraphs,
  addTagButtonLabel: i18n.t('participantContent.addTagButtonLabel'),
  tagAssignHelpLabel: i18n.t('participantContent.tagAssignHelpLabel'),
  participantSurveys,
  participantForms,
  participantDevices
};

export default config;
