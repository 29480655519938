import { ColumnConfig } from 'theme';
import i18n from '../../i18n';
import { Survey, SurveyQuestion } from '@ts/models';

export const surveyListColumnConfigs: Array<ColumnConfig<Survey>> = [
  {
    id: 'flags',
    label: i18n.t('participantSurveysPage.columnLabels.flags'),
    sortable: true
  },
  {
    id: 'name',
    label: i18n.t('participantSurveysPage.columnLabels.surveyName'),
    sortable: true,
    flex: 3
  },
  {
    id: 'completed',
    label: i18n.t('participantSurveysPage.columnLabels.completed'),
    sortable: true,
    flex: 3
  }
];

export const surveyQuestionColumnConfigs: Array<ColumnConfig<
  SurveyQuestion
>> = [
  {
    id: 'flag',
    label: i18n.t('participantSurveysPage.columnLabels.flag'),
    sortable: true
  },
  {
    id: 'question',
    label: i18n.t('participantSurveysPage.columnLabels.question'),
    sortable: true,
    flex: 3
  },
  {
    id: 'answer',
    label: i18n.t('participantSurveysPage.columnLabels.answer'),
    sortable: true,
    flex: 3
  }
];

export const participantSurveys = {
  completedLabel: i18n.t('participantSurveysPage.completedLabel'),
  totalQuestionsLabel: i18n.t('participantSurveysPage.totalQuestionsLabel'),
  flaggedAnswersLabel: i18n.t('participantSurveysPage.flaggedAnswersLabel')
};
